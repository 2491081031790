import './App.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Landing from './pages/Landing'

function App() {




    return (
        <div className="App">
            <header className="App-header">
                <p>DV Gift Cards</p>
                <Landing />
                <ToastContainer />
            </header>
        </div>
    );
}

export default App;
