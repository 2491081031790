import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import pinlocation from "../assets/pinlocation.png";

export default function TRDialog({showInfoDialog}) {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        showInfoDialog()
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth='80vw'
            >
                <DialogTitle style={{marginTop: '-10px', marginBottom: '-25px'}}>
                    <h2> 4 Digit PIN Location</h2>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <img src={pinlocation} alt="Logo" style={{
                                display: "block",
                                marginTop: "5vh",
                                marginLeft: "auto",
                                marginRight: "auto",
                                height: "80%",
                                width: "100%",
                                borderRadius: "25px"
                            }}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
