import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {Grid, Typography, TextField, CircularProgress} from '@material-ui/core';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import {checkCardNum, checkPinNum} from "../helper/validation";
import logo from "../assets/deervalley.png";
import axios from "axios";
import InfoDialog from '../components/InfoDialog'

function Landing() {

    const [cardNumber, setCardNumber] = useState('');
    const [pin, setPin] = useState('');
    const [cardError, setCardError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false)
    const [balance, setBalance] = useState(0)
    const [showBalance, setShowBalance] = useState(false)
    const [last4, setLast4] = useState('')
    const [cardNumIsValid, setCardNumIsValid] = useState(true)
    const [pinIsValid, setPinIsValid] = useState(true)
    const [auth0Token, setAuth0Token] = useState('')


    const getToken = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        if (auth0Token.length === 0) {
            try {
                await axios.post(
                    "https://apidv.us.auth0.com/oauth/token",
                    {
                        "client_id": process.env.REACT_APP_AUTH0APPKEY,
                        "client_secret": process.env.REACT_APP_AUTH0APPSECRET,
                        "audience": "https://api.deervalley.com/TokenApi/",
                        "grant_type": "client_credentials"
                    },
                    {headers: {"content-type": "application/json"}}
                ).then((response) => {
                    setAuth0Token(response.data.access_token)
                    getBalance(response.data.access_token)
                }).catch((err) => {
                    toast.error('Authentication Error')
                    setIsLoading(false);
                });
            } catch (err) {
                toast.error('Authentication Error')
                setIsLoading(false);
            }
        } else {
            getBalance(auth0Token)
        }
    }

    // get remaining gift card balance
    const getBalance = async (authToken) => {

        //this should check for a valid card and test card
        if (cardNumber.length !== 16) {
            toast.error('Invalid Card Number')
            setIsLoading(false);
            return
        }

        if (pin.length !== 4) {
            toast.error('Pin must be 4 characters')
            setIsLoading(false);
            return
        }

        let cardObject = {
            cardNumber: cardNumber,
            pin: pin,
        }

        try {
            await axios.post(process.env.REACT_APP_GIFTCARD_URL, cardObject, {headers: {Authorization: 'Bearer ' + authToken}})
                .then(res => {
                    if (res.data.Result === "APPROVED") {
                        setBalance(res.data.BalanceRemaining);
                        setLast4(cardNumber.substring(cardNumber.length - 4, cardNumber.length));
                        setCardNumber('')
                        setPin('')
                        setShowBalance(true)
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        toast.error('There was an error with this card')
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error('An error has occurred.  Please try again.')
                });
        } catch (e) {
            setIsLoading(false);
            toast.error('An error has occurred.  Please try again!')
        }
    }

    const checkCardNumber = () => {
        //show alert below text box if the user has entered a number & it is not valid
        if (cardNumber.length > 0) {
            let isValid = checkCardNum(cardNumber) && cardNumber.substring(0, 6) === '102901'
            setCardNumIsValid(isValid)
            if (isValid)
                reset()
        }
    }

    const checkPin = () => {
        //show alert below text box if the user has entered a pin & it is not valid
        if (pin.length > 0) {
            let isValid = checkPinNum(pin)
            setPinIsValid(isValid)
            if (isValid)
                reset()
        }
    }

    const reset = () => {
        setCardError(false);
        setIsLoading(false);
        setShowBalance(false)
        setBalance(0)
        setLast4('')
    };

    const handleCardNumber = (e) => {
        setCardNumber(e.target.value);
        if (e.target.value.length < 2) {
            reset()
        }
    };

    const handlePin = (e) => {
        setPin(e.target.value);
    };

    const showInfoDialog = () => {
        setShowDialog(!showDialog)
    }

    return (
        <div style={{
            backgroundColor: "whitesmoke",
            position: "absolute",
            bottom: 0,
            height: '100%',
            width: '100%',
            backgroundSize: "cover",
            overflowX: "scroll"
        }}>

            <Grid item container direction="column" alignItems="center" justify="center">
                <Grid item>
                    <img src={logo} alt="Logo" style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "30%",
                        width: "30%",
                        marginTop: "10vh"
                    }}/>
                </Grid>
                <Grid item>
                    {!isLoading ?
                        <Typography variant="h6" style={{color: '#2f836d', marginTop: '10px', marginBottom: '10px'}}>Gift
                            Card Balance Inquiry</Typography> :
                        <CircularProgress/>
                    }
                </Grid>
                {showBalance ?
                    <Grid item style={{color: "darkgreen", marginBottom: '10px'}}>${balance.toFixed(2)}</Grid> : ''}
                {showBalance ?
                    <Grid item style={{fontSize: "1rem", color: '#2f836d', marginTop: '0px', marginBottom: '10px'}}>Card
                        ending in ({last4})</Grid> : ''}
            </Grid>

            <Grid item container direction="column" alignItems="center" justify="center">
                <Grid item style={{maxWidth: "30em"}}>
                    <TextField label="Card Number" id="cardnumber" autoComplete="off" error={cardError}
                               value={cardNumber}
                               onChange={handleCardNumber} onBlur={checkCardNumber}/>
                </Grid>

                {!cardNumIsValid && <Alert severity="error">This is not a valid number</Alert>}

                <Grid item container direction="row" alignItems="center" style={{maxWidth: "185px"}}>
                    <Grid item xs={8} style={{marginTop: '10px'}}>
                        <TextField label="4 Digit Pin" id="pin" autoComplete="off" value={pin}
                                   onChange={handlePin} onBlur={checkPin}/>
                    </Grid>

                    <Grid item xs={2} style={{marginTop: '20px', marginLeft: "10Px"}}>
                        <InfoIcon color="primary" onClick={showInfoDialog}/>
                    </Grid>
                    {!pinIsValid && <Alert severity="error">4 Digits</Alert>}

                </Grid>
            </Grid>
            <Grid item container justify="center">
                <Grid item>
                    <Button variant="contained" style={{marginTop: '20px', width: '200px', height: '50px'}}
                            onClick={getToken}>Submit</Button>
                </Grid>
            </Grid>
            <Grid item container justify="center">
                <Grid item style={{color: "darkgreen", marginTop: "30px"}}>
                    <Typography variant="h6" style={{
                        fontSize: "1rem",
                        color: '#2f836d',
                        marginTop: '10px',
                        marginBottom: '10px'
                    }}> If your card does not have a 4 Digit Pin please call 800.833.2002 to get the
                        balance.</Typography>
                </Grid>
            </Grid>
            {showDialog && <InfoDialog showInfoDialog={showInfoDialog}/>}
        </div>
    );
}

export default Landing;
