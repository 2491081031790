export const checkCardNum = function (cardNum) {
    let s = 0;
    let doubleDigit = false;
    for (let i = cardNum.length - 1; i >= 0; i--) {
        let digit = +cardNum[i];
        if (doubleDigit) {
            digit *= 2;
            if (digit > 9)
                digit -= 9;
        }
        s += digit;
        doubleDigit = !doubleDigit;
    }
    return s % 10 === 0;
}



export const checkPinNum=  (pin) => {
    let isValid = false

    if (pin.length === 4)
        isValid = true

    return isValid;

}